<template>
  <div>
    <div class="ft20 cl-black cl-main ftw600">活动统计</div>

    <div class="mt20">
      <div class="form-search-box ">
        <a-form layout="inline">
          <a-form-item label="服务人员">
            <a-input v-model="search.name" placeholder="请输入活动名称"></a-input>
          </a-form-item>

          <a-form-model-item label="活动时间" >
            <a-range-picker
                :show-time="{ format: 'HH:mm' }"
                format="YYYY-MM-DD"
                :placeholder="['活动开始时间', '活动结束时间']"
                @ok="onOkAct"
                @change="changeAct"
                v-model="search.times"
            />
          </a-form-model-item>

          <a-form-item>
            <a-button @click="searchAct" type="primary">查询</a-button>
            <a-button @click="cancelAct" class="ml10">取消</a-button>
            <a-button type="primary" class="ml10" icon="export" @click="exportAct">导出数据</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>

    <div class="mt20">
      <div class="bg-w">
        <div class="pd20">
          <div class="wxb-table-gray">
            <a-table rowKey="active_id" :columns="columns" :pagination="pagination" :data-source="datas" @change="handleTableChange" :loading="loading">
            </a-table>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {listMixin} from '../../common/mixin/list.js';
export default {
  mixins: [listMixin],
  data() {
    return {
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10, //每页中显示10条数据
        total: 0,
      },
      search: {
        name: '',
        times: [],
      },
      level:[],
      columns: [
        {title: '编号',dataIndex: 'active_id',align: 'center',width:100},
        {title: '活动名称',dataIndex: 'name',align: 'left',width:280,ellipsis: true},
        {title: '所属分类',dataIndex: 'cate_name',align: 'left',ellipsis: true},
        {title: '活动开始',dataIndex: 'start_time',width:180,align: 'left',ellipsis: true},
        {title: '活动结束',dataIndex: 'end_time',width:180,align: 'left',ellipsis: true},
        {title: '报名数',dataIndex: 'apps',align: 'right',ellipsis: true},
        {title: '线下签到',dataIndex: 'offline_line',align: 'right',ellipsis: true},
        {title: '线上签到',dataIndex: 'line_signs',align: 'right',ellipsis: true},
        {title: '总签到',dataIndex: 'signs',align: 'right',ellipsis: true},
      ],
      datas: [],
      member:{},
    }
  },
  methods: {
    cancelAct(){
      this.search.name='';
      this.search.times=[];
      this.getLists();
    },
    exportAct() {
      this.loading=true;
      let href = process.env.VUE_APP_BASE_API + "housekeeping/admin/expActives?client_env="+localStorage.getItem('client-env-platform')+"&auth_token=" + encodeURIComponent(localStorage.getItem('housekeeping-token'));
      if (this.search.name != '') {
        href = href + '&name=' + encodeURIComponent(this.search.name);
      }
      if (this.search.times.length>0) {
        href = href + '&times=' + encodeURIComponent(this.search.times);
      }
      window.location.href = href;
    },
    getLists(){
      if(this.loading==true) return;
      this.loading=true;
      this.$http.api('admin/getActives',{
        name:this.search.name,
        times:this.search.times,
        limit:this.pagination.pageSize,
        page:this.pagination.current,
      }).then(res=>{
        this.pagination.total=res.total;
        this.pagination.showTotal=(total)=>"共"+res.total+"条";
        this.datas=res.list;
        this.level=res.level;
        this.loading=false;
      }).catch(res=>{
        this.loading=false;
      })
    },
    onOkAct(value) {
      console.log('onOkAct: ', value);
    },
    changeAct(date,dateString){
      this.search.times = dateString;
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.getLists();
    }
  }
}
</script>

<style>
.index-face {
  width: 44px;
  height: 44px;
  border-radius: 44px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
  border: 2px solid #FFFFFF;
}

.member-index-action {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: #FFFFFF;
  border: 1px solid #EBEDF5;
  text-align: center;
  line-height: 32px;
}
</style>
